import { TextField, MenuItem } from '@mui/material';

interface TagsInputProps {
    tags: string;  // Assuming tags is a string; adjust the type based on actual usage
    setTags: (tag: string) => void; // Function that updates the state
    readOnly: boolean;
}

function TagsInput({ tags, setTags, readOnly }: TagsInputProps) {
  return (
    <>
      {readOnly ? (
        // Display as plain text when readOnly is true
        <TextField
          label="Tags"
          value={tags}
          fullWidth
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
        />
      ) : (
        // Allow selection when readOnly is false
        <TextField
          select
          label="Tags"
          value={tags}
          onChange={(e) => setTags(e.target.value)}
          fullWidth
          margin="normal"
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </TextField>
      )}
    </>
  );
}

export default TagsInput;