import { useState, lazy, Suspense } from 'react';
import { Typography, Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Grid, Tooltip, Tab, Tabs, IconButton  } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useInView } from 'react-intersection-observer';
import { useDownloadModelMutation } from '../../slices/modelsApiSlice';
import DownloadIcon from '@mui/icons-material/Download';
import { TrainedModel } from '../../types/model';
import Loader from '../Loader';


const PastTrainingMetricsCard = lazy(() => import('../training/PastTrainingMetricsCard'));
const ModelParametersCard = lazy(() => import('./ModelParametersCard'));


export default function ModelAccordion({ model }: { model: TrainedModel }) {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.4 });
  const [ downloadModel ] = useDownloadModelMutation();

  const [ tabValue, setTabValue ] = useState('results');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };


  const handleDownloadClick = async () => {
    var data:any = {
      userId: 1,
      datasetName: model.datasetName,
      datasetVersion: model.datasetVersion,
      modelName: model.modelName,
      modelId: model.modelId
    }

    try {
      const response = await downloadModel(data).unwrap();

      const url = response
      // Create a link element, set the download attribute and append to the body
      const a = document.createElement("a");
      a.href = url;
      a.download = data.modelName + ".zip"; 
      document.body.appendChild(a);
      
      // Trigger the download by simulating a click on the anchor
      a.click();
      document.body.removeChild(a);

    } catch (error) {
      console.error('Failed to download model:', error);
    }
  }

  return (
    <>
      <div ref={ref}>
        {inView ? (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                borderBottom: '1px solid #ccc',
                bgcolor: 'background.paper'
              }}
            >
              <Grid container spacing={1} sx={{ minHeight: "120px" }}>
                <Grid item xs={12}>
                  <Typography variant="h5">{model.modelName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2"><strong>Dataset Name:</strong> {model.datasetName}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2"><strong>Dataset Version:</strong> {model.datasetVersion}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2"><strong>Trained date:</strong> {model.trainedDate}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* <Typography variant="body2"><strong>Destroyed date:</strong> {model.destroyedDate}</Typography> */}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ bgcolor: 'rgba(0, 0, 0, 0.1)' }}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{display:'flex', justifyContent:'space-between'}}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="model tabs"
                  >
                    <Tab value="results" label="Results" />
                    <Tab value="parameters" label="Parameters" />
                  </Tabs>

                  {/* Download trained model button */}
                  {model.modelTrainingStatus === 'completed' && (
                    <Tooltip title="Download Model" onClick={() => handleDownloadClick()}>
                      <IconButton aria-label="download" size="small">
                        <DownloadIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )}

                </Box>
                
                {tabValue === 'results' && (
                  <Box sx={{ p: 2 }}>
                    <Suspense fallback={<CircularProgress />}>
                      <PastTrainingMetricsCard modelId={model.modelId} />
                    </Suspense>
                  </Box>
                )}
                {tabValue === 'parameters' && (
                  <Box sx={{ p: 2 }}>
                    <Suspense fallback={<CircularProgress />}>
                      <ModelParametersCard model={model}/>
                    </Suspense>
                  </Box>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Loader circularProgressSize={30} marginBottom={4} />
        )}
      </div>
    </>
  );
}