import { Container, Typography, Grid, Card, CardContent, CardActions, Button, Divider } from "@mui/material";
import { Link as RouterLink, useNavigate } from 'react-router-dom';


const guideCardsContent = [
  {
    title: 'Step 1: Upload Your Dataset',
    description: 'Navigate to the Dataset page to upload your dataset. You can create multiple versions for each dataset and manage them easily.',
    buttonText: 'Upload Dataset',
    route: '/dataset'
  },
  {
    title: 'Step 2: Configure Hyperparameters',
    description: 'Head over to the Build page to set up your model training parameters. Adjust learning rates, model architecture, and other settings to optimize your model\'s performance.',
    buttonText: 'Configure Hyperparameters',
    route: '/hyperparameters'
  },
  {
    title: 'Step 3: Select Cloud Instances',
    description: 'Visit the Cloud Instances page to select and rent the GPU instances needed for your model training. Choose from a variety of options based on your requirements.',
    buttonText: 'Select Cloud Instances',
    route: '/instancedashboard'
  },
  {
    title: 'Step 4: Manage Deployed Instances',
    description: 'Go to the My Instances page to manage your deployed instances. Select the model you want to train on each instance and start the training process.',
    buttonText: 'Manage Deployed Instances',
    route: '/myinstances'
  },
  {
    title: 'Step 5: Monitor Training Metrics',
    description: 'Head over to the Live Training page to view live training metrics for your models. Monitor metrics such as loss, Dice coefficient, IoU, and learning rate in real-time.',
    buttonText: 'Monitor Training Metrics',
    route: '/live_training'
  },
  {
    title: 'Step 6: Review Trained Models',
    description: 'Visit the Models page to see the history of models you have trained. Review the results of your trained models and download the model artifacts if needed.',
    buttonText: 'Review Trained Models',
    route: '/model_dashboard'
  }
]

export default function HomePage() {
  const navigate = useNavigate();

  return (
    <Container maxWidth='md'>
      <Grid container item xs={12} justifyContent='center'>
        <Typography variant='h3'>Welcome to Umbr.ai</Typography>
      </Grid>
      <Grid container item xs={12} justifyContent='center'>
        <Typography variant='body2'>Here is a step by step guide on how to use this platform</Typography>
      </Grid>


      <Grid container xs={12} spacing={2}  justifyContent='center' sx={{ mt: 3 }}>
        
        {guideCardsContent.map(( cardContent, index ) => (
          <Grid item xs={7} sm={6} key={index}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant='h6'>{cardContent.title}</Typography>
                <Typography variant='body1'>
                  {cardContent.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" component={RouterLink} to={cardContent.route}>{cardContent.buttonText}</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}