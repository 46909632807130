import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const BASE_URL = process.env.REACT_APP_BACKEND_URL

const baseQuery = fetchBaseQuery({ baseUrl: BASE_URL });

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    "Datasets",
    "DeployedInstances",
    "PastInstances",
    "TrainedModelMetrics",
    "ModelParameters",
    "TrainedModelDataset"
  ],
  endpoints: (builder) => ({}),
});
