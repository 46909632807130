import {
  Box,
  Button,
  lighten,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ReactJoyride, {
  ACTIONS,
  CallBackProps,
  EVENTS,
  STATUS,
  Step,
  TooltipRenderProps,
} from "react-joyride";
import { useLocation, useNavigate } from "react-router-dom";

const steps: Step[] = [
  {
    target: "#nav-dataset",
    title: "Onboarding",
    content: "Welcome to the onboarding tour!",
    placement: "center",
  },
  {
    target: "#nav-dataset",
    title: "Dataset",
    content: "Upload and manage datasets",
    data: {
      path: "/dataset",
    },
  },
  {
    target: "#nav-build",
    title: "Build",
    content: "Build models",
    data: {
      path: "/hyperparameters",
    },
  },
  {
    target: "#nav-instances",
    title: "Add Instances",
    content: "Deploy cloud instances",
    data: {
      path: "/instancedashboard",
    },
  },
  {
    target: "#nav-my-instances",
    title: "My Instances",
    content: "Manage your cloud instances",
    data: {
      path: "/myinstances",
    },
  },
  {
    target: "#nav-training",
    title: "Training",
    content: "Train models",
    data: {
      path: "/training",
    },
  },
  {
    target: "#nav-inference",
    title: "Inference",
    content: "Make predictions",
    data: {
      path: "/inference",
    },
  },
  {
    target: "#nav-dataset",
    title: "End",
    content: "End of the onboarding tour",
    placement: "center",
  },
];

const Tooltip = ({
  index,
  step,
  backProps,
  primaryProps,
  tooltipProps,
  skipProps,
}: TooltipRenderProps) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        px: 4,
        py: 2,
        textAlign: "center",
        background: lighten(theme.palette.background.paper, 0.1),
      }}
      {...tooltipProps}
    >
      <Typography variant="h6" gutterBottom>
        {step.title}
      </Typography>
      <Typography>{step.content}</Typography>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button color="error" {...skipProps}>
          Skip
        </Button>
        {index > 0 && (
          <Button color="primary" {...backProps}>
            Back
          </Button>
        )}
        {index < steps.length - 1 && (
          <Button color="primary" {...primaryProps}>
            Next
          </Button>
        )}
        {index === steps.length - 1 && (
          <Button color="primary" {...skipProps}>
            End
          </Button>
        )}
      </Box>
    </Paper>
  );
};

export default function Onboarding() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (location.hash === "#onboarding") setRun(true);
  }, [location]);

  const callback = ({ index, action, type, status }: CallBackProps) => {
    if (type === EVENTS.STEP_AFTER) {
      switch (action) {
        case ACTIONS.PREV:
          navigate(steps[index - 1]?.data?.path);
          setStepIndex(index - 1);
          break;
        case ACTIONS.NEXT:
          navigate(steps[index + 1]?.data?.path);
          setStepIndex(index + 1);
          break;
      }
    } else if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      setStepIndex(0);
      setRun(false);
    }
  };

  return (
    <ReactJoyride
      continuous
      run={run}
      steps={steps}
      stepIndex={stepIndex}
      callback={callback}
      styles={{
        options: {
          zIndex: 2000,
          arrowColor: lighten(theme.palette.background.paper, 0.1),
        },
      }}
      tooltipComponent={Tooltip}
      showProgress
      showSkipButton
      disableOverlayClose
      disableScrolling
    />
  );
}
