import { Paper, Typography, Box, Grid } from "@mui/material";
import { LineChart } from '@mui/x-charts/LineChart';
import Loader from "../Loader";

export type TrainingData = {
  epochs: number[];
  loss: number[];
  valLoss: number[];
  diceCoefficient: number[];
  valDiceCoefficient: number[];
  iou: number[];
  valIou: number[];
  lr: number[];
};

interface InstanceTrainingMetricsProps {
    trainingData: TrainingData;
}


export default function InstanceTrainingMetrics({ trainingData }: InstanceTrainingMetricsProps){

  return(
    <Box>
      <Typography variant="h5" sx = {{mb: 1}}>Latest Update:</Typography>
      {(trainingData.epochs.length === 0) ?
        (
          <Loader circularProgressSize={40} message="Training Data is not available yet" />
        ) : (
          <Grid container spacing={2}>
            <Grid key={"Loss"} item xs={12} lg={6}>
              <Paper sx={{ padding: 2, border: '1px solid black' }}>
                <LineChart
                  xAxis={[{ id: 'Epochs', data: trainingData.epochs }]}
                  series={[
                    { id: "loss", data: trainingData.loss, color: "red", showMark: false, label: "Loss" },
                    { id: "val_loss", data: trainingData.valLoss, color: "blue", showMark: false, label: "Validation Loss" }
                  ]}
                  height={250}
                  margin={{ left: 50 }}
                />
              </Paper>
            </Grid>
            <Grid key={"Dice Coefficent"} item xs={12} lg={6}>
              <Paper sx={{ padding: 2, border: '1px solid black' }}>
                <LineChart
                  xAxis={[{ id: 'Epochs', data: trainingData.epochs }]}
                  series={[
                    { id: "dice_coefficient", data: trainingData.diceCoefficient, color: "green", showMark: false, label: "Dice Coef." },
                    { id: "val_dice_coefficient", data: trainingData.valDiceCoefficient, color: "purple", showMark: false, label: "Val Dice Coef." }
                  ]}
                  height={250}
                  margin={{ left: 50 }}
                />
              </Paper>
            </Grid>
            <Grid key={"IOU"} item xs={12} lg={6}>
              <Paper sx={{ padding: 2, border: '1px solid black' }}>
                <LineChart
                  xAxis={[{ id: 'Epochs', data: trainingData.epochs }]}
                  series={[
                    { id: "iou", data: trainingData.iou, color: "orange", showMark: false, label: "IOU" },
                    { id: "val_iou", data: trainingData.valIou, color: "yellow", showMark: false, label: "Validation IOU" }
                  ]}
                  height={250}
                  margin={{ left: 50 }}
                />
              </Paper>
            </Grid>
            <Grid key={"Learning Rate"} item xs={12} lg={6}>
              <Paper sx={{ padding: 2, border: '1px solid black' }}>
                <LineChart
                  xAxis={[{ id: 'Epochs', data: trainingData.epochs }]}
                  series={[
                    {id: "lr", data: trainingData.lr, color: "black", showMark: false, label: "Learning Rate"}
                  ]}
                  height={250}
                  margin={{ left: 50 }}
                />
              </Paper>
            </Grid>
          </Grid>
        )
      }
    </Box>
  )   
};
