import { Box, CircularProgress, Typography } from '@mui/material';

interface LoaderProps {
  circularProgressSize?: number;
  message?: string;
  width?: number;
  bgcolor?: string;
  borderRadius?: number;
  padding?: number;
  margin?: number;
  marginBottom?: number;
}

export default function Loader({circularProgressSize, message, width, bgcolor, padding, borderRadius, margin, marginBottom}: LoaderProps) {
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',  
      justifyContent: 'center', 
      alignItems: 'center',        
      textAlign: 'center',     
      width: width,
      bgcolor: bgcolor,
      borderRadius: borderRadius,
      p: padding,
      m: margin,
      mb: marginBottom,
    }}>
      <CircularProgress size={circularProgressSize}/>
      { message && (
        <Typography variant="h6" sx={{ marginTop: 2 }}>{message}</Typography>
      )}
    </Box>
  );
}