import { FC } from 'react';
import { FormGroup, Typography, TextField } from '@mui/material';

interface StrParameterProps {
    hyperparam_name: string;
    label: string;
    value: string;
    isRequired: boolean;
    maxLength?: number;
    onChange: (value: string) => void;
}


const StrParameter: FC<StrParameterProps> = ({ hyperparam_name, label, value, isRequired, maxLength, onChange }) => {
  return (
    <FormGroup>
      <Typography variant="subtitle1" gutterBottom>
        { hyperparam_name }
      </Typography>
      <TextField
        id="outlined-basic"
        label={label}
        variant="outlined"
        size="small"
        required={isRequired}
        fullWidth
        InputProps={{
          inputProps: {
            type: 'text',
            maxLength: maxLength ? maxLength : 20,
          },
        }}
        value={value}
        onChange={(event) =>{onChange(event.target.value)}}
      />

  </FormGroup>
  )
}

export default StrParameter