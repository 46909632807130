import { apiSlice } from './apiSlice';
import { DeployedInstance, TrainingModelDetail } from '../types/cloudinstance';

export const vastApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableVastInstances: builder.query<any, void>({
      query: () => 'instance/get_available_instances',
      transformResponse: (response: any) => {
         return JSON.parse(response)
      },
      keepUnusedDataFor: 0,
    }),

    deployInstance: builder.mutation<any, { instance_id: string; user_id: number }>({
      query: ({ instance_id, user_id }) => ({
        url: 'instance/deploy_instance',
        method: 'POST',
        body: { instance_id, user_id },
      }),
      invalidatesTags: ['DeployedInstances'],
    }),

    getDeployedInstances: builder.query<DeployedInstance[], number>({
      query: (user_id) => `instance/get_deployed_instances?user_id=${user_id}`,
      providesTags: ['DeployedInstances'],
    }),

    destroyInstance: builder.mutation<any, { user_id: number; instance_id: string }>({
      query: ({ user_id, instance_id }) => ({
        url: 'instance/destroy_instance',
        method: 'POST',
        body: { user_id, instance_id },
      }),
      invalidatesTags: ['PastInstances']
      // invalidatesTags: ['DeployedInstances'],
    }),
    
    updateInstanceTrainingModel: builder.mutation<any, { user_id: number; instance_id: string; training_model_info: TrainingModelDetail }>({
      query: ({ user_id, instance_id, training_model_info }) => ({
        url: 'instance/update_instance_training_model',
        method: 'POST',
        body: { user_id, instance_id, training_model_info },
      }),
      invalidatesTags: ['DeployedInstances'],
    }),
  }),
});

export const {
  useGetAvailableVastInstancesQuery,
  useDeployInstanceMutation,
  useGetDeployedInstancesQuery,
  useDestroyInstanceMutation,
  useUpdateInstanceTrainingModelMutation,
} = vastApiSlice;
