import { FC } from 'react';
import { FormGroup, Typography, Select, MenuItem, FormControl, FormHelperText, InputLabel } from '@mui/material';

interface DropdownParameterProps {
    hyperparam_name: string;
    selectItems: string[];
    value: string;
    isRequired: boolean;
    helperText: string;
    onChange: (value: string) => void;
}

const DropdownParameter: FC<DropdownParameterProps> = ({ hyperparam_name, selectItems, value, onChange, isRequired, helperText }) => {
  return (
    <FormGroup>
      <Typography variant="subtitle1" gutterBottom>
      { hyperparam_name }
      </Typography>

      <FormControl variant="outlined" size="small" fullWidth>
        <InputLabel id="demo-simple-select-helper-label">{isRequired ? "Required*" : ''}</InputLabel>
        <Select
          id="demo-simple-select-helper-label"
          defaultValue=""
          value={value}
          label={isRequired ? "Required*" : ''}
          onChange={(event) =>{onChange(event.target.value)}}
        >
          {selectItems.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </FormGroup>
  )
}

export default DropdownParameter