import { Typography, Container, Grid, Alert } from '@mui/material'
import DeployedInstanceCard from '../../components/instances/DeployedInstanceCard';
import { useGetUserDatasetsQuery } from '../../slices/datasetApiSlice';
import { useGetDeployedInstancesQuery } from '../../slices/vastApiSlice';
import Loader from '../../components/Loader';

export default function UserInstanceDashboard(){
  const { data: userDatasets = [], error: isLoadDatasetError, isLoading: isLoadingDataset } = useGetUserDatasetsQuery(1);
  const { data: deployedInstances = [], error: isLoadInstancesError, isLoading: isLoadingInstances, refetch: refetchDeployedInstances } = useGetDeployedInstancesQuery(1);

  if (isLoadingDataset || isLoadingInstances){
    return(
      <Loader circularProgressSize={50} message="Loading data..." />
    )
  }

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Typography variant="h3">Current Deployed Instances</Typography>
        </Grid>
        { deployedInstances.length === 0 ? (
          <Grid item xs={12}>
            <Alert severity='info'>There are no deployed instances!</Alert>
          </Grid>
          ) : (
            <>
            {deployedInstances.map((instance) => (
              <DeployedInstanceCard key={instance.instance_id} instance={instance} userDatasets={userDatasets} fetchDeployedInstances={refetchDeployedInstances} />
            ))}
          </>
        )}
      </Grid>
    </Container>
    )
}