import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const uploadImageApiSlice = createApi({
  reducerPath: 'uploadImageApi',
  baseQuery: fetchBaseQuery(),
  endpoints: (builder) => ({
    uploadImageFile: builder.mutation<void, { url: string, file: File, updateProgress: () => void }>({
      query: ({ url, file }) => ({
        url,
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type, 
          'Accept': '*/*'
        }
      }),
      async onQueryStarted({ url, file, updateProgress }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          updateProgress();
        } catch (error: any) {
          console.error(`HTTP error! Status: ${error.status} for file: ${file.name}`);
        }
      },
    }),
  }),
});

export const { 
  useUploadImageFileMutation,
} = uploadImageApiSlice;
