import { useRef, useEffect } from 'react';

export default function useHorizontalScroll() {
  const elRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = (e: WheelEvent) => {
        // Check if element is scrollable
        const scrollable = el.scrollWidth > el.clientWidth;
        if (!scrollable || e.deltaY === 0) return;
        e.preventDefault();
        el.scrollTo({ left: el.scrollLeft + e.deltaY });
      };
      // { passive: false } is required to prevent default for wheel event
      el.addEventListener('wheel', onWheel, { passive: false });
      return () => {
        el.removeEventListener('wheel', onWheel);
      };
    }
  }, []);

  return elRef;
}
