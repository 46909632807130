import { useState, useEffect, FC } from 'react';
import { Box, Button, Chip, Grid, Modal, Typography, Alert } from '@mui/material';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DeployedInstance, InstanceStatus } from '../../types/cloudinstance';
import { Dataset, VersionInformation } from '../../types/dataset';
import { ModelParameters } from '../../types/model';
import { DropdownParameter } from '../inputFields';
import { useGenerateModelFilesAndTransfer } from '../../utils/modelUtils';
import { useUpdateInstanceTrainingModelMutation, useDestroyInstanceMutation } from '../../slices/vastApiSlice';
import { useStartTrainingMutation, useStopTrainingMutation } from '../../slices/trainingApiSlice';
import SnackbarNotification from '../SnackbarNotification';
import Loader from '../Loader';

type InstanceComponentProps = {
  instance: DeployedInstance;
  userDatasets: Dataset[];
  fetchDeployedInstances: () => void;
};

const DeployedInstanceCard: FC<InstanceComponentProps> = ({ instance, userDatasets, fetchDeployedInstances }) => {
  const [selectedDatasetName, setSelectedDatasetName] = useState("");
  const [selectedDatasetVersion, setSelectedDatasetVersion] = useState("");
  const [selectedModel, setSelectedModel] = useState("");

  // Handle Modal
  const [openModal, setOpenModal] = useState(false);
  const [openDestroyModal, setOpenDestroyModal] = useState(false);

  // Handle retrieval of dataset versions and models
  const [datasetVersions, setDatasetVersions] = useState<VersionInformation[]>([]);
  const [models, setModels] = useState<ModelParameters[]>([]);
  
  const [destroyInstanceId, setDestroyInstanceId] = useState("");
  const [isTrainingLoader, setIsTrainingLoader] = useState(false);

  // query and mutation hooks
  const [ updateInstanceTrainingModel, { isLoading: isLoadingUpdateModelInfo, isError: isErrorUpdateModelInfo, isSuccess: isSuccessUpdateModelInfo, error: errorUpdateModelInfo }] = useUpdateInstanceTrainingModelMutation();
  const [ destroyInstance, { isLoading: isLoadingDestroyInstance, isError: isErrorDestroyInstance, isSuccess: isSuccessDestroyInstance, error: errorDestroyInstance }] = useDestroyInstanceMutation();
  const [ startTraining ] = useStartTrainingMutation();
  const [ stopTraining ] = useStopTrainingMutation();
  const { generateModelFilesAndTransfer } = useGenerateModelFilesAndTransfer();

  // Snackbar Notification
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'warning'>('success');

  // ---------------------- Snackbar Notification ---------------------- //
  // Handle Snackbar fields
  const handleSnackbarFields = (open: boolean, severity: string, msg: string) => {
    setSnackbarOpen(open);
    setSnackbarSeverity(severity as 'success' | 'error' | 'warning');
    setSnackbarMessage(msg);
  }
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  //-------------------------------------------------------------------//
  
  //--------------------Handling choosing of model---------------------//
  const handleChooseModel = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDatasetName('');
    setSelectedDatasetVersion('');
    setSelectedModel('');
    setDatasetVersions([]);
    setModels([]);
  };
  
  const handleSelectedDatasetNameChange = (newValue: string) => {
    setSelectedDatasetName(newValue)
    setSelectedDatasetVersion('')
    setSelectedModel('')
  };

  const handleSelectedDatasetVersionChange = (newValue: string) => {
    setSelectedDatasetVersion(newValue)
  };

  const handleSelectedModelChange = (newValue: string) => {
    setSelectedModel(newValue)
  };

  // display versions based on selected dataset
  useEffect(() => {
    let selected_dataset = userDatasets.find( ds => ds.datasetName === selectedDatasetName )
    
    if (selected_dataset) {
      setDatasetVersions(selected_dataset.versions)
    }
    
  }, [selectedDatasetName])

  //display models based on selected version
  useEffect(() => {
    let selected_dataset_version = datasetVersions.find( ds => ds.versionName === selectedDatasetVersion )

    if (selected_dataset_version) {
      setModels(selected_dataset_version.models)
    }
  }, [selectedDatasetVersion])
  
  // saving selected model to train in the instance
  const handleSubmitTrainingModel = async () => {
    let training_model_info = {
      "datasetName": selectedDatasetName,
      "datasetVersion": selectedDatasetVersion,
      "modelName": selectedModel
    }
    await updateInstanceTrainingModel({ user_id: 1, instance_id: instance.instance_id, training_model_info: training_model_info }).unwrap();
  }
  // handle closing of modal after updating model info
  useEffect(() => {
    if (isSuccessUpdateModelInfo) {
      handleCloseModal();
      fetchDeployedInstances();
    }
  }, [isSuccessUpdateModelInfo])
//-------------------------------------------------------------------//

// --------------------Start Training button-------------------------//
  const handleStartTrainingButton = async () => {
    setIsTrainingLoader(true);

    const datasetInformation = userDatasets.find((dataset) => dataset.datasetName === instance.datasetName);
    if (!datasetInformation) {
      console.error(`Dataset ${instance.datasetName} not found`);
      setIsTrainingLoader(false);
      return;
    }

    const versionInformation = datasetInformation.versions.find((version) => version.versionName === instance.datasetVersion);
    const modelInformation = versionInformation?.models.find((model) => model.model_label === instance.modelName);
    if (!modelInformation) {
      console.error(`Model ${instance.modelName} not found`);
      setIsTrainingLoader(false);
      return;
    }

    const data = {
      patience: modelInformation.patience,
      batchSize: modelInformation.batchSize,
      numEpochs: modelInformation.numEpochs,
      tileSize: modelInformation.tileSize,
      aug: modelInformation.aug,
      model_label: modelInformation.model_label,
      dataset_name: instance.datasetName,
      dataset_ver: instance.datasetVersion,
      userID: 1,
      instanceID: instance.instance_id
    }

    try {
      // Generate model files and transfer them to the instance
      const modelTransferResponse = await generateModelFilesAndTransfer(data, instance.instance_id);
      console.log('Transfer successful:', modelTransferResponse);

      if (modelTransferResponse) {
        // If the transfer was successful, start training
        await startTraining({ user_id: 1, instance_id: instance.instance_id }).unwrap();

        handleSnackbarFields(true, "success", "Training Started Successfully");

        setIsTrainingLoader(false);

        fetchDeployedInstances();

      } else {
        console.error("Failed to generate model files and transfer.");
        setIsTrainingLoader(false);
        handleSnackbarFields(true, "error", "Failed to generate model files and transfer");
      }
    } catch (error) {
      setIsTrainingLoader(false);
      console.error("An error occurred:", error);
      handleSnackbarFields(true, "error", "An error occurred");
    }
  }
//-------------------------------------------------------------------//

// --------------------Stop Training button--------------------------//
  const handleStopTrainingButton = async () => {
    try {
      await stopTraining({ user_id: 1, instance_id: instance.instance_id }).unwrap();
      handleSnackbarFields(true, "warning", "Training Stopped");
      fetchDeployedInstances();

    } catch (error: any) {
      let msg = ""
      if (error.status === 400 || error.response.status === 500) {
        msg = "Unable to stop training, please try again later.";
      } else {
        console.error("An error occurred:", error);
        msg = "An error occurred";
      }
      handleSnackbarFields(true, "error", msg);
    };
  }
//-------------------------------------------------------------------//

// -------------------Destroy Instance button-------------------------//
const handleDestroyButton = () => {
    setOpenDestroyModal(true);
    setDestroyInstanceId(instance.instance_id);
  };

  const handleCloseDestroyModal = () => { 
    setOpenDestroyModal(false);
  }

  const handleDestroyInstance = async () => {
    await destroyInstance({ user_id: 1, instance_id: instance.instance_id })
  }

  useEffect(() => {
    if (isSuccessDestroyInstance) {
      setTimeout(() => {
        fetchDeployedInstances();
        handleCloseDestroyModal();
      }, 2000);
    }
  }, [isSuccessDestroyInstance])
//-------------------------------------------------------------------//


// Render model select dropdown if there are models for the selected dataset and version
  const renderModelSelect = () => {
    if (!selectedDatasetVersion) {
      return
    }
    else if (selectedDatasetVersion && models.length === 0) {
      return (
        <Grid item xs={12} >
          <Alert severity="info">There are no models for this dataset</Alert>
        </Grid>
      )
    }
    else {
      return (
        <Grid item xs={12} >
        <DropdownParameter
          hyperparam_name='Model'
          selectItems={models.map((model) => model.model_label)}
          value={selectedModel}
          onChange={handleSelectedModelChange}
          isRequired={false}
          helperText=""
        />
      </Grid>
      )
    }
  }

  const renderInstanceStatusChip = (status: InstanceStatus) => {
    if (status === "stopped") {
      return <Chip size='small' color='error' label='Stopped'/>
    }
    else if (status === "training") {
      return <Chip size='small' color='info' label='Training'/>
    }
    else if (status === "no_model") {
      return <Chip size='small' color='warning' label='No Model'/>
    }
  }

  return (
    <>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
      <Grid item xs={10} sm={10} md={9} lg={9}>
        <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '4px', height: '100%' }}>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={6}>
              <Typography variant="h5">Instance {instance.instance_id}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} sx={{ textAlign: 'right' }}>
              {instance.datasetName === undefined ? (
                <Chip size='small' color='warning' label='Missing Model'/>
              ) : (
                renderInstanceStatusChip(instance.instanceStatus)
              )}
            </Grid>
            {instance.datasetName !== undefined && (
              <>
                <Grid item xs={6} sm={6}>
                  <Typography variant="body2">
                    <strong>Dataset Name:</strong> {instance.datasetName}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant="body2">
                    <strong>Dataset Version:</strong> {instance.datasetVersion}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant="body2">
                    <strong>Model Name:</strong> {instance.modelName}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Typography variant="body2">
                    <strong>Deployed date:</strong> {instance.created}
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12} sx={{ mt: 3 }}></Grid>
            <Grid item xs={6} sm={4}>
              <Button variant="contained" color='secondary' size='small' endIcon={<ModelTrainingIcon />} onClick={handleChooseModel}>
                Choose Model
              </Button>
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                sx={{
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)', 
                    opacity: '0.1 !important'
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                >
                <Box sx={{ width: 400, bgcolor: 'background.paper', p: 2, borderRadius: 2 }}>
                  <h2 id="child-modal-title">Choose model to train</h2>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DropdownParameter
                        hyperparam_name='Dataset Name'
                        selectItems={userDatasets.map((dataset) => dataset.datasetName)}
                        value={selectedDatasetName}
                        onChange={handleSelectedDatasetNameChange}
                        isRequired={false}
                        helperText=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                      <DropdownParameter
                        hyperparam_name='Version'
                        selectItems={datasetVersions.map((version) => version.versionName)}
                        value={selectedDatasetVersion}
                        onChange={handleSelectedDatasetVersionChange}
                        isRequired={false}
                        helperText=""
                        />
                    </Grid>
                    {renderModelSelect()}
                    <Grid item xs={12}>
                      <Button onClick={handleCloseModal}>Close</Button>
                      <Button 
                        variant="text" 
                        endIcon={<CheckIcon />} 
                        disabled={!selectedModel}
                        onClick={handleSubmitTrainingModel}
                        >
                        Confirm
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
            </Grid>
            <Grid item xs={6} sm={4}>
              {instance.instanceStatus === "stopped" || instance.instanceStatus === "no_model" ?
                isTrainingLoader ? (
                  <Loader circularProgressSize={60} />
                ) : ( 
                  <Button disabled={instance.datasetName === undefined} onClick={handleStartTrainingButton} variant="contained" size='small' endIcon={<PlayCircleFilledIcon />}>
                    Start Training
                  </Button>
                ) :
                <Button onClick={handleStopTrainingButton} variant="contained" endIcon={<DoDisturbOnIcon />} color="error" size='small'>
                  Stop Training
                </Button>
              }

            </Grid>
            <Grid item xs={6} sm={4}>
              <Button onClick={handleDestroyButton} variant="contained" size='small' endIcon={<CancelIcon />} style={{ backgroundColor: '#d3d3d3', color: 'black'}}>
                Destroy Instance
              </Button>
              <Modal
                open={openDestroyModal}
                onClose={handleCloseDestroyModal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                sx={{
                  '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)', 
                    opacity: '0.1 !important'
                  },
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                > 
                <>
                  { !isLoadingDestroyInstance && !isSuccessDestroyInstance && !isErrorDestroyInstance &&
                    <Box sx={{ width: 400, bgcolor: 'background.paper', p: 2, borderRadius: 2 }}>
                      <h2 id="child-modal-title">Confirmation</h2>
                      <p id="child-modal-description">
                        Do you want to destroy GPU instance ID: {destroyInstanceId}?
                      </p>
                      <Box 
                        sx={{
                          alignItems: 'center', // This aligns items vertically.
                          display: 'flex',
                          justifyContent: 'flex-end' // This aligns items to the right.
                        }}
                        >
                        <Button onClick={handleCloseDestroyModal}>Close</Button>
                        <Button onClick={handleDestroyInstance}>
                          Destroy
                        </Button>
                      </Box>
                    </Box>
                  }
                  {isLoadingDestroyInstance && 
                    <Loader message="Destroying Instance..." width={400} bgcolor="background.paper" borderRadius={2} padding={2} />
                  }
                  {isSuccessDestroyInstance && 
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 400,
                        bgcolor: 'background.paper',
                        p: 2,
                        borderRadius: 2
                      }}>
                        <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'green' }} />
                        <Typography variant="h6" sx={{ marginTop: 2 }}>Successfully Destroyed!</Typography>
                    </Box>
                  }
                  {isErrorDestroyInstance && 
                    <Box
                    sx={{
                      display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: 400,
                        bgcolor: 'background.paper',
                        p: 2,
                        borderRadius: 2
                      }}>
                        <Typography variant="h6" sx={{ marginTop: 2 }}>Failed to destroy instance</Typography>
                    </Box>
                  }
                </>
              </Modal>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default DeployedInstanceCard;
