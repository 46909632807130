import { useState, useEffect, ChangeEvent } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { Container, Box, Typography, TextField, Grid, Button, IconButton, CircularProgress, Tooltip, LinearProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UploadImage from '../../components/dataset/UploadImage';
import useUploadFiles, { UploadURLs } from '../../utils/uploadFiles';
import { VersionInformation } from '../../types/dataset';
import { useRecordDatasetInfoMutation, useRecordNewDatasetVersionMutation, DatasetData } from '../../slices/datasetApiSlice';
import { useGetUserDatasetsQuery, useGetPresignedUrlsMutation } from '../../slices/datasetApiSlice';
import { getCurrentFormattedDate } from '../../utils/time/getFormattedTime';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TagsInput from '../../components/dataset/TagsInput/TagsInput';
import TaskInput from '../../components/dataset/TaskInput/TaskInput';
import ImageOverlay from '../../components/dataset/ImageOverlay';
import { processImage } from '../../utils/imagePixelClass';
import { styled } from '@mui/material/styles';

// Styling for loading modal
const FadeInTypography = styled(Typography)({
  transition: 'opacity 1s ease-in-out',
  opacity: 1,
  '&.hidden': {
    opacity: 0,
  },
});

export default function DatasetUpload() {
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [labelFiles, setLabelFiles] = useState<File[]>([]);
  const [imageFilesNames, setImageFilesNames] = useState<string[]>([]);
  const [labelFilesNames, setLabelFilesNames] = useState<string[]>([]);
  const location = useLocation();
  const { dataset } = location.state || {};
  const [version, setVersion] = useState(dataset?.version || '');
  const [datasetName, setDatasetName] = useState(dataset?.datasetName || '');
  const [tags, setTags] = useState(dataset?.tags || '');
  const [task, setTask] = useState(dataset?.task || '');
  const [classes, setClasses] = useState(dataset?.filteredClasses || ['']);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uniqueClasses, setUniqueClasses] = useState<number[]>([]);
  const navigate = useNavigate();
  const readOnly = dataset !== undefined;

  const { data: userDatasets = [], error: isLoadDatasetError, isLoading: isLoadingDataset } = useGetUserDatasetsQuery(1);
  const [ recordDatasetInfo ] = useRecordDatasetInfoMutation();
  const [ recordNewDatasetVersion ] = useRecordNewDatasetVersionMutation();
  const [ getPresignedUrls ] = useGetPresignedUrlsMutation();
  const { uploadFiles } = useUploadFiles();

  const [progress, setProgress] = useState(0);
  const [fadeClass, setFadeClass] = useState('');
  const [progressLabel, setProgressLabel] = useState("Preparing to upload...");
  const [nextMessage, setNextMessage] = useState("");
  
  if(dataset){
    console.log(dataset);
  }
  useEffect(() => {
    if (uploadComplete) {
      setTimeout(() => navigate('/dataset'), 1000);
    }
  }, [uploadComplete]);

  useEffect(() => {
    if (labelFiles.length > 0) {
      let updatedUniqueCLasses = [];
      processImage(labelFiles[0], setUniqueClasses, (uniqueClasses) => {
        updatedUniqueCLasses = uniqueClasses;

        if(updatedUniqueCLasses.length > classes.length){
          // Add to new blank classes to match the number of unique classes
          const newClasses = [...classes];
          for (let i = classes.length; i < updatedUniqueCLasses.length; i++) {
          newClasses.push('');
          }
          setClasses(newClasses);
        } else if (updatedUniqueCLasses.length < classes.length ) {
          // Remove classes that are not needed
          const newClasses = classes.filter((_:string, i:number) => i < updatedUniqueCLasses.length);
          setClasses(newClasses);
        }
      });
    };
  },[labelFiles]);

  

  useEffect(() => {
    if (nextMessage) {
      // Fade out current message
      setFadeClass('hidden');
      const timeout = setTimeout(() => {
        // After fade out, set the new message and fade in
        setProgressLabel(nextMessage);
        setFadeClass('');
      }, 1000); // Match the duration of the fade out transition

      return () => clearTimeout(timeout);
    }
  }, [nextMessage]);

  const handleAddClass = () => {
    console.log(classes);
    if (!readOnly) {
      if (labelFiles.length > 0 && uniqueClasses.length === classes.length) {
        alert("Cannot add class, Pixel class will not match with the number of classes.");
        return;
        
      }
      setClasses([...classes, '']);
    }
  };

  const handleRemoveClass = (index: number) => {
    if (!readOnly) {
      if (labelFiles.length > 0 && uniqueClasses.length === classes.length) {
        alert("Cannot remove class, Pixel class will not match with the number of classes.");
        return;
        
      }
      const newClasses = classes.filter((_:string, i:number) => i !== index);
      setClasses(newClasses);}
  };

  const handleClassChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    if (!readOnly) {
      const newClasses = [...classes];
      newClasses[index] = event.target.value;
      setClasses(newClasses);
    }
  };

  const renderClassFields = () => {
    const onDragEnd = (result:any) => {
      if (!result.destination) {
        return;
      }
      const items = Array.from(classes);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setClasses(items);
      console.log(items);
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-classes">
          {(provided:any) => (
            <Grid container {...provided.droppableProps} ref={provided.innerRef}>
              {classes.map((cls:string, index:number) => (
                <Draggable key={index} draggableId={`class-${index}`} index={index} isDragDisabled={readOnly}>
                  {(provided:any) => (
                  <Tooltip title={!readOnly ? "Drag to up/down to change Class number" : ""} placement={!readOnly ? "top" : undefined} arrow={!readOnly}>
                    <Grid item xs={12} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={2} sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignItems: 'left',
                            }}>
                                <Typography sx={{ cursor: 'move' }}>Class {index + 1}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                              <TextField
                                label="Class"
                                value={cls}
                                onChange={(e) => handleClassChange(index, e as ChangeEvent<HTMLInputElement>)}
                                fullWidth
                                margin="normal"
                                InputProps={{ readOnly: readOnly }}
                                />
                            </Grid>
                        {!readOnly && (
                          <Grid item xs={1} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',}}>
                            <IconButton onClick={() => handleRemoveClass(index)} color="error" aria-label="delete class">
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        )}
                        </Grid>
                      </Grid>
                      </Grid>
                    </Grid>
                  </Tooltip>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
    );
  };


  const handleUploadButton = async () => {
    // Check for existing dataset name
    if (userDatasets.find((dataset) => dataset.datasetName === datasetName)) {
      alert("Dataset name already exists. Please use a different name.");
      return;
    }

    // For uploading a different version, check if the version already exists
    if (dataset && dataset.versions.find((v: VersionInformation) => v.versionName === version)) {
      alert("Version already exists. Please use a different version.");
      return;
    }

    const filteredClasses = classes.filter((cls: string) => cls !== '');
    if (!datasetName || !version || labelFiles.length === 0 || imageFiles.length === 0 || filteredClasses.length === 0) {
      alert("Please enter both dataset name and version before uploading. And drop your image and label files.");
      return;
    }

    // Check for different number of image and label files
    if (imageFiles.length !== labelFiles.length) {
      alert("Different number of image and label files detected.");
      return;
    }

    // Check for empty classes
    if (filteredClasses.includes('')) {
      alert("Empty classes are not allowed.");
      return;
    }

    // Check for duplicate classes
    const duplicateClasses = new Set(filteredClasses);
    if (duplicateClasses.size !== filteredClasses.length) {
      alert("Duplicate classes are not allowed.");
      return;
    }

    // Mount loading component
    setUploading(true);

    // Creating imageUploadUrlstest and labelUploadUrlstest to store the presigned URLs
    let imageUploadUrls:UploadURLs = {};
    let labelUploadUrls:UploadURLs = {};

    // Get presigned URLs for uploading files
    try{
      if (imageFilesNames.length !== 0) {
        try {
          let response = await getPresignedUrls({
            userId: 1,
            datasetName: datasetName,
            version: version,
            type: "training",
            fileNames: imageFilesNames
          }).unwrap();
          imageUploadUrls = response;
        }
        catch (error) {}
      };
      
      if (labelFiles.length !== 0 ) {
        try {
          let response = await getPresignedUrls({
            userId: 1,
            datasetName: datasetName,
            version: version,
            type: "labels",
            fileNames: labelFilesNames
          }).unwrap();
          labelUploadUrls = response;
        }
        catch (error) {
        }
      };

      // Add training_ infront of the keys for imageUploadUrls and labels_ infront of the keys for labelUploadUrls
      const allFiles = [
        ...imageFiles.map(file => new File([file], `training_${file.name}`, {
            type: file.type,
            lastModified: file.lastModified
        })),
        ...labelFiles.map(file => new File([file], `labels_${file.name}`, {
            type: file.type,
            lastModified: file.lastModified
        }))
      ];

      const prefixedImageUrls = Object.fromEntries(
        Object.entries(imageUploadUrls).map(([key, value]) => [`training_${key}`, value])
      );
      const prefixedLabelUrls = Object.fromEntries(
        Object.entries(labelUploadUrls).map(([key, value]) => [`labels_${key}`, value])
      );

      const allUploadUrls = { ...prefixedImageUrls, ...prefixedLabelUrls };
      // // Upload images
      await uploadFiles(allFiles, allUploadUrls, setProgress, setNextMessage);

      // package all the text fields into json
            
      const pixelClassMapping = Object.fromEntries(filteredClasses.map((key:String, index:number) => [key, uniqueClasses[index]]));

      const currentDateTime = getCurrentFormattedDate()
      const data: DatasetData = dataset? 
      {
        "versionName": version,
        "uploaded": currentDateTime,
        "models": []
      }:
      {
        id: 1,
        created: currentDateTime,
        updated: currentDateTime,
        datasetName,
        tags: tags.toString(),
        task,
        filteredClasses,
        pixelClassMapping,
        versions:[{
          "versionName": version,
          "uploaded": currentDateTime,
          "models": []
        }]
      };
      
      if (!dataset) {
        try {
          await recordDatasetInfo(data).unwrap();
        }
        catch (error) {
          console.error('Error uploading data: ', error);
        }
      } else {
        await recordNewDatasetVersion({
          versionInformation: data,
          userId: 1,
          datasetName: datasetName,
        }).unwrap();
      }
    }
    catch (error) {
      console.error('Error uploading files: ', error);
    }
    finally {
      // Redirect to dataset page using Routerlink
      setUploading(false);
      setUploadComplete(true);
    }
  };


  if (uploading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',  
          justifyContent: 'center', 
          alignItems: 'center',     
          height: '100vh',          
          textAlign: 'center',   
          width: '350px'    
        }}
      >
        <Box sx={{ width: '100%', mt: 3 }}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" color="textSecondary">{`${Math.round(progress * 100) / 100 }%`}</Typography>
        </Box>
        <Box sx={{ width: '100%', mt: 2 }}>
          <FadeInTypography variant="h6" sx={{ marginTop: 2 }} className={fadeClass}>{progressLabel}</FadeInTypography>
        </Box>
      </Box>
    );
  };

  if (uploadComplete) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',  
          justifyContent: 'center', 
          alignItems: 'center',     
          height: '100vh',          
          textAlign: 'center'       
        }}
      >
        <CheckCircleOutlineIcon color="success" fontSize="large"/>
        <Typography variant="h6" sx={{ marginTop: 2 }}>Upload Complete!</Typography>
      </Box>
    );
  };


  return (
    <Container maxWidth="lg">
      <Grid container spacing={1} alignItems="center" justifyContent="center">
        <Grid item xs={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={RouterLink}
            to="/dataset"
            startIcon={<ArrowBackIcon />}
          >
            Go Back
          </Button>
        </Grid>
        <Grid item xs={10}></Grid>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>Create / Update a Dataset Repository</Typography>
        </Grid>
        
        <Grid item xs={5}>
          <TextField
            label="Dataset Name"
            value={datasetName}
            onChange={(e) => setDatasetName(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: readOnly,
              inputProps: {
                maxLength: 20,
              },
            }}
          />
        </Grid>

        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Typography variant="h3" component="div" style={{ lineHeight: 'normal' }}>/</Typography>
        </Grid>

        <Grid item xs={5}>
          <TextField
            label="Version"
            value={version}
            onChange={(e) => setVersion(e.target.value)}
            fullWidth
            margin="normal"
            InputProps={{
              inputProps: {
                maxLength:  5,
              },
            }}
          />
        </Grid>

        <Grid item xs={5}>
          <TagsInput
          tags={tags}
          setTags={setTags}
          readOnly={readOnly}
          />
        </Grid>
        <Grid item xs={false} sm={2} />  
        <Grid item xs={5}>
          <TaskInput
            task={task}
            setTask={setTask}
            readOnly={readOnly}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Classes</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={5}>
              {renderClassFields()}
              {!readOnly && 
                <Grid item xs={12} sx={{display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                      alignItems: 'center',}}>
                  <IconButton onClick={handleAddClass} color="primary" aria-label="add new class">
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Grid>
                }
            </Grid>
            <Grid item xs={7} >
              <ImageOverlay baseImage={imageFiles[0]} labelImage={labelFiles[0]} classes={uniqueClasses} />
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
      <Box mt={2} />
      <Box mt={2} />
      
      <Box>
      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Upload Files</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}> 
          <Typography variant="h6">Upload Images</Typography>
          <UploadImage
            files={imageFiles}
            setFileNames={setImageFilesNames}
            setFiles={setImageFiles}
          />
        </Grid>
        <Grid item xs={12} md={6}> 
          <Typography variant="h6">Upload Labels</Typography>
          <UploadImage
            files={labelFiles}
            setFileNames={setLabelFilesNames}
            setFiles={setLabelFiles}
          />
        </Grid>
      </Grid>
    <Box mt={2} />
    </Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        startIcon={<CloudUploadIcon />}
        onClick={handleUploadButton}
      >
        Upload
      </Button>
    </Container>
  );
}
