import { Container, Skeleton, Typography } from '@mui/material';

export default function Report() {
  return (
    <Container>
      <Typography variant="h3" mb={4}>
        Generate Report
      </Typography>
      <Skeleton variant="rounded" height={800} />
    </Container>
  );
}
