import { apiSlice } from './apiSlice';

export const trainingApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startTraining: builder.mutation<any, { user_id: number; instance_id: string }>({
      query: ({ user_id, instance_id }) => ({
        url: 'templating/start_training',
        method: 'POST',
        body: { user_id, instance_id },
        invalidatesTags: ['DeployedInstances'],
      }),
    }),

    stopTraining: builder.mutation<any, { user_id: number; instance_id: string }>({
      query: ({ user_id, instance_id }) => ({
        url: 'templating/stop_training',
        method: 'POST',
        body: { user_id, instance_id },
        invalidatesTags: ['DeployedInstances'],
      }),
    }),
  }),
});

export const {
  useStartTrainingMutation,
  useStopTrainingMutation,
} = trainingApiSlice;
