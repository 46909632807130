import { Add } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Chip,
  Container,
  darken,
  Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { useGetUserDatasetsQuery } from '../../slices/datasetApiSlice';
import { Dataset, VersionInformation } from '../../types/dataset';
import useHorizontalScroll from '../../utils/useHorizontalScroll';

export default function DatasetDashboard() {
  const {
    data: userDatasets = [],
    error: isLoadDatasetError,
    isLoading: isLoadingDataset,
  } = useGetUserDatasetsQuery(1);

  if (isLoadingDataset) {
    return <Loader circularProgressSize={50} message="Loading data..." />;
  }

  return (
    <Container>
      <Grid
        container
        spacing={1}
        direction="row"
        alignItems="end"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={{ xs: 2, md: 4 }}
      >
        <Grid item>
          <Typography sx={{ typography: { xs: 'h4', md: 'h3' } }}>
            Datasets
          </Typography>
          <Typography variant="subtitle1">
            Datasets are the backbone of your machine learning projects.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            component={Link}
            to="/dataset_upload"
          >
            Upload
          </Button>
        </Grid>
      </Grid>
      <Stack spacing={2}>
        {userDatasets.map((dataset) => (
          <DatasetItem key={dataset.datasetName} dataset={dataset} />
        ))}
      </Stack>
    </Container>
  );
}
function DatasetItem({ dataset }: { dataset: Dataset }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const handleAddDataset = (dataset: Dataset) => {
    navigate('/dataset_upload', { state: { dataset } });
  };

  const scrollRef = useHorizontalScroll();

  return (
    <Paper variant="outlined">
      <Grid container>
        <Grid item xs={12} md={3}>
          <Box
            display="flex"
            height="100%"
            flexDirection={{
              xs: 'row',
              md: 'column',
            }}
            borderRight={{
              md: `1px solid ${theme.palette.divider}`,
            }}
            borderBottom={{
              xs: `1px solid ${theme.palette.divider}`,
              md: 'none',
            }}
          >
            <Box padding={2}>
              <Typography variant="h5">{dataset.datasetName}</Typography>
              <Typography variant="subtitle1">{dataset.created}</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}>
          <Stack
            ref={scrollRef}
            direction="row"
            padding={{ xs: 1, md: 2 }}
            spacing={{ xs: 1, md: 2 }}
            height="100%"
            alignItems="stretch"
            className="thin-scrollbar"
            sx={{
              overflowX: 'scroll',
              backgroundColor: darken(theme.palette.background.paper, 0.025),
            }}
          >
            <Button
              onClick={() => handleAddDataset(dataset)}
              aria-label="add dataset version"
              variant="outlined"
            >
              <Add />
            </Button>
            {dataset.versions
              .slice()
              .reverse()
              .map((version) => {
                return (
                  <DatasetVersion
                    key={version.versionName}
                    dataset={dataset}
                    version={version}
                  />
                );
              })}
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
}

function DatasetVersion({
  dataset,
  version,
}: {
  dataset: Dataset;
  version: VersionInformation;
}) {
  return (
    <Card sx={{ minWidth: 200 }}>
      <CardActionArea
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          height: '100%',
        }}
        component={Link}
        to="/hyperparameters"
        state={{ dataset, version }}
        // TODO: use `useLocation` in /hyperparameters to populate the dataset and version information
      >
        <CardHeader
          title={version.versionName}
          titleTypographyProps={{ variant: 'h6' }}
          subheader={version.uploaded}
        />
        <CardContent
          sx={{ mt: 'auto', p: 1, display: 'flex', flexWrap: 'wrap' }}
        >
          {version.models.map((model, index) => (
            <Box py={0.25} px={0.5} key={index}>
              <Chip
                color="primary"
                size="small"
                clickable
                label={model.model_label}
              />
            </Box>
          ))}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
