import { useState, SetStateAction, DragEvent, ChangeEvent, Dispatch } from 'react';
import { ImageList, ImageListItem, IconButton, ImageListItemBar  } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface UploadImageProps {
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  setFileNames: Dispatch<SetStateAction<string[]>>;
}


export default function UploadImage({ files, setFiles, setFileNames }: UploadImageProps) {
  const [isLoading, setIsLoading] = useState(false);

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true); 

    const uploadedfiles: File[] = []; 
    const fileNames: string[] = [];

    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        if (event.dataTransfer.items[i].kind === 'file') {
          const file = event.dataTransfer.items[i].getAsFile();
          if (file !== null) {
            uploadedfiles.push(file);
            fileNames.push(file.name);
          }
        }
      }
      setFiles(uploadedfiles);
      setFileNames(fileNames);
      
    } else {
      // Fall back to support older browsers
      const files = Array.from(event.dataTransfer.files).filter((file): file is File => file !== null);
      const fileNames = files.map(file => file.name);
      setFiles(files);
      setFileNames(fileNames);
    }
    setTimeout(() => setIsLoading(false), 1000); 
  };
  
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsLoading(true); 

    if (event.target.files) {
      const fileArray = Array.from(event.target.files); 
      setFiles(fileArray);
    }
    setTimeout(() => setIsLoading(false), 1000); 
  };

  const handleRemoveImage = (index: number): void => {
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
  };

  const renderImagelist = () => {
    return(
    <ImageList sx={{  height: 250 }} cols={3} rowHeight={164}>
        {files.map((file, index) => (
          <ImageListItem key={index}>
            <img 
              src={URL.createObjectURL(file)}
              alt={file.name} 
              loading="lazy"
              style={{
                width: '100%',      
                height: '100%',      
                objectFit: 'cover',  
                objectPosition: 'center'  
              }}
              />
            <ImageListItemBar title={file.name}></ImageListItemBar>
            <IconButton
              aria-label="delete"
              onClick={() => handleRemoveImage(index)}
              sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  color: 'rgba(255, 255, 255, 0.54)',
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                  margin: '8px',
                  '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.9)',
                  }
              }}
              >
              <CloseIcon />
            </IconButton>
          </ImageListItem>
        ))}
      </ImageList>);
  };


  return (
    <div>
      <input
        type="file"
        webkitdirectory=""
        directory=""
        onChange={handleFileChange}
        style={{ display: 'none' }}
        multiple
      />
      <div
        onDragOver={(e) => { e.preventDefault(); e.stopPropagation(); }}
        onDrop={handleDrop}
        style={{ border: '1px dashed gray', padding: '20px', textAlign: 'center' }}
      >
        {isLoading ? <p>Loading...</p> : <p>Drag images here (Max 10MB Each)</p>}
      </div>
      {files.length > 0 && renderImagelist()}
    </div>
  );
}


