import { FC } from 'react';

interface ClassIndicatorProps {
  classes: number[];
}

const ClassIndicator: FC<ClassIndicatorProps> = ({ classes }) => {
  const colors = ['red', 'blue', 'vibrantGreen', 'vibrantYellow', 'vibrantPurple'];
  
  const getColorForClass = (cls: number): string => {
    return colors[cls % colors.length];
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
      {classes.map((cls, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px' }}>
          Class {index + 1}
          <div style={{ width: '15px', height: '15px', backgroundColor: getColorForClass(index), marginLeft: '5px' }} />
        </div>
      ))}
    </div>
  );
}

export default ClassIndicator;