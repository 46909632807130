import { TextField, MenuItem } from '@mui/material';

interface TaskInputProps {
    task: string;  // Assuming tags is a string; adjust the type based on actual usage
    setTask: (tag: string) => void; // Function that updates the state
    readOnly: boolean;
}

const tasks = [
    { value: 'Classification', label: 'Classification' },
    { value: 'Segmentation', label: 'Segmentation' },
    // Add other tasks as needed
];

function TaskInput({ task, setTask, readOnly }: TaskInputProps) {
  return (
    <>
      {readOnly ? (
        // Display as plain text when readOnly is true
        <TextField
          label="Task"
          value={task}
          fullWidth
          margin="normal"
          InputProps={{
            readOnly: true,
          }}
        />
      ) : (
        // Allow selection when readOnly is false
        <TextField
          select
          label="Task"
          value={task}
          onChange={(e) => setTask(e.target.value)}
          fullWidth
          margin="normal"
          InputProps={{
            readOnly: readOnly,
          }}
          >
          {tasks.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
          </TextField>
      )}
    </>
  );
}

export default TaskInput;