import { useState } from 'react';
import { Container, Typography, Grid, Checkbox, Switch, Tooltip, Button } from '@mui/material';
import SnackbarNotification from '../../components/SnackbarNotification';
import ModelAccordion from '../../components/trainedModel/ModelAccordion';
import { useGetTrainedModelsQuery, useDeleteTrainedModelsMutation } from '../../slices/modelsApiSlice';
import { TrainedModel } from '../../types/model';
import Loader from '../../components/Loader';

export default function ModelsDashboard() {
  const { data: trainedModels, error: isErrorTrainedModels, isLoading: isLoadingTrainedModels, isSuccess: isSuccessTrainedModels, refetch: refetchTrainedModels } = useGetTrainedModelsQuery({ user_id: 1 });
  const [ deleteMode, setDeleteMode ] = useState(false); // State for delete mode
  const [ selectedDeleteModels, setSelectedDeleteModels ] = useState<string[]>([]);
  const [ deleteTrainedModels ] = useDeleteTrainedModelsMutation();

  console.log(selectedDeleteModels);

  // Snackbar Notification
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error' | 'warning'>('success');

  // ---------------------- Snackbar Notification ---------------------- //
  // Handle Snackbar fields
  const handleSnackbarFields = (open: boolean, severity: string, msg: string) => {
    setSnackbarOpen(open);
    setSnackbarSeverity(severity as 'success' | 'error' | 'warning');
    setSnackbarMessage(msg);
  }
  
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };  
  //-------------------------------------------------------------------//

  // Handle checkbox selection for deleting instances
  const handleDeleteInstance = (modelId: string) => {
    if (selectedDeleteModels.includes(modelId)){
      setSelectedDeleteModels(selectedDeleteModels.filter(item => item !== modelId));
    }
    else {
      setSelectedDeleteModels([...selectedDeleteModels, modelId]);
    }
  };
  
  // Delete selected instances when delete button is clicked
  const handleDeleteSelectedInstances = async () => {
    try {
      await deleteTrainedModels({ user_id: 1, model_ids: selectedDeleteModels }).unwrap();
      setDeleteMode(false);
      handleSnackbarFields(true, 'success', 'Successfully deleted instances');
      setSelectedDeleteModels([]);
      refetchTrainedModels();
    }
    catch (error) {
      handleSnackbarFields(true, 'error', 'Failed to delete instances');
    }
  }

  if (isLoadingTrainedModels || !isSuccessTrainedModels) {
    return (
      <Loader circularProgressSize={50} message="Loading data..." />
    );
  }

  return (
    <>
      <SnackbarNotification
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
      <Container maxWidth="md">
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={8}>
            <Typography variant="h3">Trained Models</Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Tooltip id="tooltip-id" title="Select instances to delete">
              <Switch
                checked={deleteMode}
                onChange={() => setDeleteMode(!deleteMode)}
                name="delete-instances-toggle"
                inputProps={{ 'aria-label': 'toggle delete instances' }}
                color='error'
                />
              </Tooltip>
          </Grid>
        </Grid>

        {deleteMode && (
          <Grid container item xs={12} sx={{display: 'flex', justifyContent: 'flex-end', mb: 2}}>
            <Button 
              variant="contained"  
              size='small' 
              color="error"
              onClick={handleDeleteSelectedInstances}
              >
                Delete Selected Instances
              </Button>
          </Grid>
        )}          

        {trainedModels.map((model: TrainedModel, index: number) => (
          <Grid container key={index} alignItems='center'>
            {deleteMode && (
              <Grid item xs={2} md={1} sx={{ mb: 4 }}>
                <Checkbox
                  checked={selectedDeleteModels.includes(model.modelId)}
                  onChange={() => handleDeleteInstance(model.modelId)}
                  inputProps={{ 'aria-label': 'select instance to delete' }}
                />
              </Grid>
            )}

            <Grid item xs={deleteMode ? 10 : 12} md={deleteMode ? 11 : 12} sx={{ mb: 4 }}>
              <ModelAccordion model={model} key={index} />
            </Grid>
          </Grid>
        ))}
      </Container>
    </>
  );
}
