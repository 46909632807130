import { Brightness4, Brightness7 } from '@mui/icons-material';
import { IconButton, PaletteMode } from '@mui/material';
import { createContext, useContext, useMemo } from 'react';
import useSavedState from '../utils/useSavedState';

type ColorModeContextType = {
  mode: PaletteMode;
  toggleMode: () => void;
};

export const ColorModeContext = createContext<ColorModeContextType>({
  mode: 'light',
  toggleMode: () => {},
});

export function ToggleColorModeButton() {
  const { mode, toggleMode } = useContext(ColorModeContext);

  return (
    <IconButton onClick={toggleMode} color="inherit">
      {mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
    </IconButton>
  );
}

export const useColorMode = () => {
  const [mode, setMode] = useSavedState<PaletteMode>('colorMode', () => {
    const prefersDarkMode = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches;
    return prefersDarkMode ? 'dark' : 'light';
  });

  const toggleMode = useMemo(() => {
    return () => {
      setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };
  }, [setMode]);

  return { mode, toggleMode };
};
