import { useState, useEffect } from 'react';
import { Typography, Container, Box, Table, TableBody, Button, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Modal, TableSortLabel, TablePagination } from '@mui/material';
import StartIcon from '@mui/icons-material/Start';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { InstanceDetail } from '../../types/cloudinstance';
import { useGetAvailableVastInstancesQuery, useDeployInstanceMutation } from '../../slices/vastApiSlice';
import Loader from '../../components/Loader';

interface DataItem {
  Model: string;
  cpu_ghz: string;
  vCPUs: string;
  RAM: string;
  Disk: string;
  ['$/hr']: string;
}

export default function InstanceDashboard() {
  const [openModal, setOpenModal] = useState(false);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof DataItem>('Model');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentId, setCurrentId] = useState("");

  const { data: instanceList, isLoading: isLoadingInstanceList, error: instanceListError, refetch: refetchInstanceList } = useGetAvailableVastInstancesQuery();
  const [deployInstanceMutation, { isLoading: isLoadingDeployInstance, isSuccess: isSuccessDeployInstance, isError: isErrorDeployInstance, error: errorDeployInstance, reset: resetDeployMutation }] = useDeployInstanceMutation();
  

  //-------------------------------------Sort Functions----------------------------------------//
  const stableSort = (array: InstanceDetail[], comparator: (a: InstanceDetail, b: InstanceDetail) => number): InstanceDetail[] => {
    const stabilizedThis: [InstanceDetail, number][] = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1]; // To maintain the order of items with equal values
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order: 'asc' | 'desc', orderBy: keyof DataItem): ((a: InstanceDetail, b: InstanceDetail) => number) => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a: InstanceDetail, b: InstanceDetail, orderBy: keyof DataItem): number => {
    // handles missing value in data retrieved from vast.ai
    const aValue = parseFloat(a[orderBy] === "-" ? "0" : a[orderBy]);
    const bValue = parseFloat(b[orderBy] === "-" ? "0" : b[orderBy]);
  
    if (orderBy === 'vCPUs' || orderBy === 'RAM' || orderBy === 'Disk' || orderBy === '$/hr' || orderBy === 'cpu_ghz') {
      return bValue - aValue;
    } else if (b[orderBy] < a[orderBy]) {
      return -1;
    } else if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  

  const handleRequestSort = (property: keyof DataItem) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenModal = (InstanceId: string) => {
    setCurrentId(InstanceId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeployInstance = async (instance_id: string) => {
    console.log(instance_id);
    await deployInstanceMutation({ instance_id, user_id: 1 });
  };

  useEffect(() => {
    
    if (isSuccessDeployInstance || isErrorDeployInstance) {
      refetchInstanceList();

      setTimeout(() => {
        handleCloseModal(); 
        
        //reset the mutation states
        resetDeployMutation();
      }, 3000);
    }
  }, [isSuccessDeployInstance, isErrorDeployInstance]);

  if (isLoadingInstanceList) {
    return (
      <Loader circularProgressSize={50} message="Loading data..." />
    )
  }

  if (instanceListError) {
    return (
        <Typography>Error fetching data</Typography>
    );
  }

  const instanceKeys = Object.keys(instanceList);
  if (instanceKeys.length === 0) {
    return (
      <Container>
        <Typography>No data available</Typography>
      </Container>
    );
  }

  const sortedData = stableSort(
    Object.keys(instanceList).map(key => instanceList[key]),
    getComparator(orderDirection, orderBy)
  );

  return (
    <Container maxWidth="md">
      <Typography variant="h3">GPU Instances</Typography>
      <Box mt={2} />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table" stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell>
                  ID
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'Model'}
                    direction={orderBy === 'Model' ? orderDirection : 'asc'}
                    onClick={() => handleRequestSort('Model')}
                  >
                    Model
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    active={orderBy === 'cpu_ghz'}
                    direction={orderBy === 'cpu_ghz' ? orderDirection : 'asc'}
                    onClick={() => handleRequestSort('cpu_ghz')}
                  >
                    CPU GHz
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    active={orderBy === 'vCPUs'}
                    direction={orderBy === 'vCPUs' ? orderDirection : 'asc'}
                    onClick={() => handleRequestSort('vCPUs')}
                  >
                    vCPUs
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    active={orderBy === 'RAM'}
                    direction={orderBy === 'RAM' ? orderDirection : 'asc'}
                    onClick={() => handleRequestSort('RAM')}
                  >
                    RAM (GB)
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    active={orderBy === 'Disk'}
                    direction={orderBy === 'Disk' ? orderDirection : 'asc'}
                    onClick={() => handleRequestSort('Disk')}
                  >
                    Disk (GB)
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'>
                  <TableSortLabel
                    active={orderBy === '$/hr'}
                    direction={orderBy === '$/hr' ? orderDirection : 'asc'}
                    onClick={() => handleRequestSort('$/hr')}
                  >
                    Cost per Hour
                  </TableSortLabel>
                </TableCell>
                <TableCell align='right'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((instance) => (
                  <TableRow key={instance.ID}>
                    <TableCell>{instance.ID}</TableCell>
                    <TableCell>{instance.Model}</TableCell>
                    <TableCell align='right'>{instance['cpu_ghz']}</TableCell> 
                    <TableCell align='right'>{instance['vCPUs']}</TableCell>
                    <TableCell align='right'>{instance['RAM']}</TableCell>
                    <TableCell align='right'>{instance['Disk']}</TableCell>
                    <TableCell align='right'>${instance['$/hr']}</TableCell>
                    <TableCell align='right'>
                      <Tooltip title="Deploy GPU instance">
                        <IconButton
                          onClick={() => handleOpenModal(instance.ID)}
                          color="primary"
                          aria-label="deploy gpu instance"
                        >
                          <StartIcon />
                        </IconButton>
                      </Tooltip>
                      <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                        sx={{
                          '& .MuiBackdrop-root': {
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            opacity: '0.1 !important'
                          },
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <>
                        { !isLoadingDeployInstance && !isSuccessDeployInstance && !isErrorDeployInstance &&
                          <Box sx={{
                            width: 400,
                            bgcolor: 'background.paper',
                            p: 2,
                            borderRadius: 2
                          }} >
                              <>
                                <h2 id="child-modal-title">Confirmation</h2>
                                <p id="child-modal-description">
                                  Do you want to deploy GPU instance ID: {currentId}?
                                </p>
                                <Box
                                  sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'flex-end'
                                  }}>
                                  <Button onClick={handleCloseModal}>Close</Button>
                                  <Button onClick={() => handleDeployInstance(currentId)}>
                                    Deploy
                                  </Button>
                                </Box>
                              </>
                            </Box>
                            }
                          {isLoadingDeployInstance && 
                            <Loader message="Deploying Instance..." width={400} bgcolor="background.paper" borderRadius={2} padding={2} />
                          }
                          {isSuccessDeployInstance && 
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 400,
                                bgcolor: 'background.paper',
                                p: 2,
                                borderRadius: 2
                              }}>
                                <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'green' }} />
                                <Typography variant="h6" sx={{ marginTop: 2 }}>Successfully Deployed!</Typography>
                            </Box>
                          }
                          {isErrorDeployInstance && 
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 400,
                                bgcolor: 'background.paper',
                                p: 2,
                                borderRadius: 2
                              }}>
                                <Typography variant="h6" sx={{ marginTop: 2 }}>Failed to deploy instance</Typography>
                            </Box>
                          }
                        </>
                      </Modal>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={sortedData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </TableContainer>
      </Paper>
    </Container>
  );
}
