import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

interface InferenceCardProps {
  data: {
    inference_job_name: string;
    model_name: string;
    image_name: string;
    instance_id: number;
    status: string;
    start_time: string;
    end_time: string;
  };
}

const InferenceCard: React.FC<InferenceCardProps> = ({ data }) => {
  return (
    <Grid item xs={12} sm={12}>
        <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '4px', height: '100%' }}>
          <Typography variant="h6">{data.inference_job_name}</Typography>

        </Box>
    </Grid>
  );
};

export default InferenceCard;