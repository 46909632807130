import { FC } from 'react';
import { FormGroup, Typography, TextField } from '@mui/material';

interface IntParameterProps {
  hyperparam_name: string;
  min: number;
  max: number;
  step: number;
  value: number;
  isRequired: boolean;
  helperText: string;
  onChange: (value: number) => void;
}

const IntParameter: FC<IntParameterProps> = ({ hyperparam_name, min, max, step, value, isRequired, helperText, onChange }) => {
  return (
    <FormGroup>
      <Typography variant="subtitle1" gutterBottom>
        { hyperparam_name }
      </Typography>
      <TextField
        id="outlined-basic"
        label={isRequired ? "Required" : ''}
        variant="outlined"
        size="small"
        required={isRequired}
        helperText={helperText}
        fullWidth
        InputProps={{
          inputProps: {
            type: 'number',
            min: {min},
            max: {max},
            step: {step},
          },
        }}
        value={value}
        onChange={(event) => {
          let value = parseInt(event.target.value);
          if (value > 10000) {
            value = 10000; 
          }
          onChange(value);
        }}
      />
    </FormGroup>
  )
}

export default IntParameter;