import {
  Grow,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { useRef, useState } from 'react';

type NestedMenuItemProps = {
  label: string;
  children: React.ReactNode;
};

export function NestedMenuItem({ label, children }: NestedMenuItemProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLLIElement>(null);

  const hoverInteraction = {
    onMouseEnter: () => setOpen(true),
    onMouseLeave: () => setOpen(false),
  };

  return (
    <>
      <MenuItem
        ref={anchorRef}
        sx={{ cursor: 'default' }}
        disableRipple
        disableTouchRipple
        {...hoverInteraction}
      >
        <ListItemText primary={label} />
      </MenuItem>
      <Popper
        sx={{ zIndex: 1320 }}
        open={open}
        anchorEl={anchorRef.current}
        placement="auto-start"
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper {...hoverInteraction}>
              <MenuList>{children}</MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
