import { useState, useEffect } from 'react';

const useSavedState = <T>(
  key: string,
  initialValue: T | (() => T)
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => {
    const savedState = localStorage.getItem(key);
    if (savedState) {
      return JSON.parse(savedState);
    } else if (typeof initialValue === 'function') {
      return (initialValue as () => T)();
    } else {
      return initialValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
    console.log('Saved: ', state);
  }, [key, state]);

  return [state, setState];
};

export default useSavedState;
