import { apiSlice } from './apiSlice';
import { Dataset, VersionInformation, } from '../types/dataset';

export type DatasetData = Dataset | VersionInformation;

export const datasetApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserDatasets: builder.query<Dataset[], number>({
      query: (userId) => `dataset/get_user_datasets?user_id=${userId}`,
      providesTags: ['Datasets'],
    }),

    recordDatasetInfo: builder.mutation<any, DatasetData>({
      query: (datasetInformation) => ({
        url: 'dataset/record_dataset_information',
        method: 'POST',
        body: { data: datasetInformation },
      }),
      invalidatesTags: ['Datasets'],
    }),

    recordNewDatasetVersion: builder.mutation<any, { versionInformation: DatasetData; userId: number; datasetName: string }>({
      query: ({ versionInformation, userId, datasetName }) => ({
        url: 'dataset/record_new_dataset_version',
        method: 'POST',
        body: {
          dataset_name: datasetName,
          user_id: userId,
          data: versionInformation,
        },
      }),
      invalidatesTags: ['Datasets'],
    }),

    getPresignedUrls: builder.mutation<any, { userId: number; datasetName: string; version: string; type: string; fileNames: string[] }>({
      query: ({ userId, datasetName, version, type, fileNames }) => ({
        url: 'dataset/generate_presigned_links',
        method: 'POST',
        body: {
          data: {
            userId: userId,
            datasetName: datasetName,
            version: version,
            type: type,
            FileNames: fileNames,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetUserDatasetsQuery,
  useRecordDatasetInfoMutation,
  useRecordNewDatasetVersionMutation,
  useGetPresignedUrlsMutation
} = datasetApiSlice;
