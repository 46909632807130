import { useState, useEffect } from "react";
import startWebsocket from "./utils/startWebsocket";
import MetricCharts from "./MetricCharts";

export type TrainingData = {
  epochs: number[];
  loss: number[];
  valLoss: number[];
  diceCoefficient: number[];
  valDiceCoefficient: number[];
  iou: number[];
  valIou: number[];
  lr: number[];
};

interface TrainingMetricsCardProps {
  modelId: string;
}


export default function TrainingMetricsCard({ modelId }: TrainingMetricsCardProps){
  const [trainingData, setTrainingData] = useState<TrainingData>({ epochs: [], loss: [], valLoss: [], diceCoefficient: [], valDiceCoefficient: [], iou: [], valIou: [], lr: [] });

  useEffect(() => {
    const cleanupWebSocket = startWebsocket(modelId, setTrainingData);
    
    return () => cleanupWebSocket()
    
  }, []);


  return(
    <MetricCharts trainingData={trainingData} />
  )   
};
