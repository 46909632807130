import {
  AddCircle,
  Assessment,
  BatchPrediction,
  CloudCircle,
  Dataset,
  ExpandLess,
  ExpandMore,
  HelpOutline,
  Menu,
  ModelTraining,
  Psychology,
  ScatterPlot,
  Settings
} from '@mui/icons-material';
import {
  Collapse,
  Drawer,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../App.css';
import { ToggleColorModeButton } from './ColorMode';

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.text.primary, // Overwrites the default grey color
}));

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'absolute',
  bottom: '1rem',
  left: '1rem',
  color: theme.palette.getContrastText(theme.palette.primary.light),
  backgroundColor: theme.palette.primary.light,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const SideNavbar = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [instancesOpen, setInstancesOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false); // State to toggle drawer

  useEffect(() => {
    // Opens the drawer and instances list when onboarding
    // FIXME: Breaks when `isMobile` is true
    // @see /src/components/Onboarding.tsx
    if (location.hash === '#onboarding') {
      setDrawerOpen(true);
      setInstancesOpen(true);
    }

    if (isMobile) {
      setDrawerOpen(false);
    }
  }, [location.hash, isMobile]);

  return (
    <>
      <IconButton onClick={() => setDrawerOpen(true)} sx={{ m: 1 }}>
        <Menu sx={{ color: theme.palette.text.primary }} />
      </IconButton>
      <Drawer
        className="fixed-sidebar"
        anchor="left"
        variant={isMobile ? 'temporary' : 'permanent'}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)} // Triggered when clicking outside the drawer
      >
        <List>
          <ListItem>
            <Link to="/">
              <ListItemText
                primary="Multi-Class uNet"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </Link>
            <ListItemSecondaryAction>
              <ToggleColorModeButton />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItemButton component={Link} to="/dataset">
            <StyledListItemIcon>
              <Dataset />
            </StyledListItemIcon>
            <ListItemText primary="Dataset" id="nav-dataset" />
          </ListItemButton>
          {/* rename the path from /hyperparameters to /build or something relevant after creating the revamped Build page */}
          <ListItemButton component={Link} to="/hyperparameters">
            <StyledListItemIcon>
              <Settings />
            </StyledListItemIcon>
            <ListItemText primary="Build" id="nav-build" />
          </ListItemButton>
          <ListItemButton onClick={() => setInstancesOpen(!instancesOpen)}>
            <StyledListItemIcon>
              <CloudCircle />
            </StyledListItemIcon>
            <ListItemText primary="Cloud Instances" sx={{ mr: 2 }} />
            {instancesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={instancesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                component={Link}
                to="/instancedashboard"
                sx={{ pl: 4 }}
              >
                <StyledListItemIcon>
                  <AddCircle />
                </StyledListItemIcon>
                <ListItemText primary="Add Instances" id="nav-instances" />
              </ListItemButton>
              <ListItemButton component={Link} to="/myinstances" sx={{ pl: 4 }}>
                <StyledListItemIcon>
                  <ScatterPlot />
                </StyledListItemIcon>
                <ListItemText primary="My Instances" id="nav-my-instances" />
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton component={Link} to="/live_training">
            <StyledListItemIcon>
              <ModelTraining />
            </StyledListItemIcon>
            <ListItemText primary="Training" id="nav-training" />
          </ListItemButton>
          <ListItemButton component={Link} to="/model_dashboard">
            <StyledListItemIcon>
              <Psychology />
            </StyledListItemIcon>
            <ListItemText primary="Models" />
          </ListItemButton>
          <ListItemButton component={Link} to="/inference">
            <StyledListItemIcon>
              <BatchPrediction />
            </StyledListItemIcon>
            <ListItemText primary="Inference" id="nav-inference" />
          </ListItemButton>
          <ListItemButton component={Link} to="/report">
            <StyledListItemIcon>
              <Assessment />
            </StyledListItemIcon>
            <ListItemText primary="Report" />
          </ListItemButton>
        </List>
        <StyledFab size="small" onClick={() => navigate('#onboarding')}>
          <HelpOutline />
        </StyledFab>
      </Drawer>
    </>
  );
};

export default SideNavbar;
