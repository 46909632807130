import { useState, useEffect, ChangeEvent } from 'react';
import { Box, Button, Container, Grid, Typography, Modal, Alert, TextField, Divider, Tooltip } from '@mui/material';
import InferenceCard from '../../components/inference/InferenceCard';
import { useGetUserDatasetsQuery } from '../../slices/datasetApiSlice';
import { useGetTrainedModelsQuery } from '../../slices/modelsApiSlice';
import { useGetDeployedInstancesQuery } from '../../slices/vastApiSlice';
import { VersionInformation } from '../../types/dataset';
import { ModelParameters } from '../../types/model';
import { DropdownParameter } from '../../components/inputFields';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const sampleData = [
  {
    inference_job_name: "Inference Job 1",
    model_name: "Model 2.zip",
    image_name: "image2.zip",
    instance_id: 10265024,
    status: "Done",
    start_time: "2024-07-25T04:24:10Z",
    end_time: "2024-07-25T04:24:10Z"
  },
  {
    inference_job_name: "Inference Job 2",
    model_name: "Model 3.zip",
    image_name: "image3.zip",
    instance_id: 10265025,
    status: "In Progress",
    start_time: "2024-07-26T04:24:10Z",
    end_time: "2024-07-26T04:24:10Z"
  },
  {
    inference_job_name: "Inference Job 3",
    model_name: "Model 4.zip",
    image_name: "image4.zip",
    instance_id: 10265026,
    status: "Done",
    start_time: "2024-07-27T04:24:10Z",
    end_time: "2024-07-27T04:24:10Z"
  }
]

function InferenceDashboard() {
  const { data: userDatasets = [], error: isLoadDatasetError, isLoading: isLoadingDataset } = useGetUserDatasetsQuery(1);
  const { data: deployedInstances = [], error: isLoadInstancesError, isLoading: isLoadingInstances, refetch: refetchDeployedInstances } = useGetDeployedInstancesQuery(1);
  const { data: trainedModels = [], error: isLoadTrainedModelsError, isLoading: isLoadingTrainedModels } = useGetTrainedModelsQuery({ user_id: 1 });

  if (deployedInstances){
    console.log(deployedInstances)
  }
  // Handle filter buttons
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const [filteredInstances, setFilteredInstances] = useState(sampleData);

  // Handle retrieval of dataset versions and models
  const [datasetVersions, setDatasetVersions] = useState<VersionInformation[]>([]);
  const [models, setModels] = useState<ModelParameters[]>([]);

  // Handle selection of deployed instance
  const [selectedDeployedInstance, setSelectedDeployedInstance] = useState<number | null>(null);
  const [InferenceJobName, setInferenceJobName] = useState<string>('');

  // Handle Selection of dataset, version and model
  const [selectedDatasetName, setSelectedDatasetName] = useState("");
  const [selectedDatasetVersion, setSelectedDatasetVersion] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState('');

  // Handle Modal
  const [openModal, setOpenModal] = useState(false);

  const handleChooseModel = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedDatasetName('');
    setSelectedDatasetVersion('');
    setSelectedModel('');
    setDatasetVersions([]);
    setModels([]);
  };

  const handleChooseDeployedInstance = (value: string) => {
    setSelectedDeployedInstance(parseInt(value));
  };
  
  const handleSelectedDatasetNameChange = (newValue: string) => {
    setSelectedDatasetName(newValue)
    setSelectedDatasetVersion('')
    setSelectedModel('')
  };

  const handleSelectedDatasetVersionChange = (newValue: string) => {
    setSelectedDatasetVersion(newValue)
  };

  const handleSelectedModelChange = (newValue: string) => {
    setSelectedModel(newValue)
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const validZipTypes = ["application/zip", "application/x-zip-compressed", "multipart/x-zip"];
    console.log(file);
    if (file && (validZipTypes.includes(file.type) || file.name.endsWith(".zip"))) {
      setUploadedFileName(file.name);
    } else {
      alert("Please upload a zip file.");
      event.target.value = "";
      setUploadedFileName("") 
    }
  };
  

  // display versions based on selected dataset
  useEffect(() => {
    let selected_dataset = trainedModels.find( (ds: { datasetName: string; }) => ds.datasetName === selectedDatasetName )
    
    if (selected_dataset) {
      setDatasetVersions(selected_dataset.versions)
    }
    
  }, [selectedDatasetName])

  //display models based on selected version
  useEffect(() => {
    let selected_dataset_version = datasetVersions.find( ds => ds.versionName === selectedDatasetVersion )

    if (selected_dataset_version) {
      // Filter trained models
      const traindedModels = selected_dataset_version.models.filter((model) => model.isTrained === true)
      setModels(traindedModels);
    }
  }, [selectedDatasetVersion])

  // Filter instances based on their status
  const handleFilterButtons = (type: string) => {
    if (activeButton === type) {
      setActiveButton(null);
      setFilteredInstances(sampleData);
      return;
    }
    setActiveButton(type);

    const filtered = sampleData.filter((instance) =>
      type === 'In Progress' ? instance.status === 'In Progress' : instance.status === 'Done'
    );
    setFilteredInstances(filtered);
  };

  const handleAddInferenceButton = () => {
    setOpenModal(true);
  };

  const handleSubmitTrainingModel = () => {
    // Handle submit logic here
    setOpenModal(false);
  };

  // Render model select dropdown if there are models for the selected dataset and version
  const renderModelSelect = () => {
    if (!selectedDatasetVersion) {
      return
    }
    else if (selectedDatasetVersion.length === 0 ) {
      return (
        <Grid item xs={12} >
          <Alert severity="info">You have not trained a model!</Alert>
        </Grid>
      )
    }
    else if (selectedDatasetVersion && models.length === 0) {
      return (
        <Grid item xs={12} >
          <Alert severity="info">There are no trained models for this dataset</Alert>
        </Grid>
      )
    }
    else {
      return (
        <Grid item xs={12} >
        <DropdownParameter
          hyperparam_name='Model'
          selectItems={models.map((model) => model.model_label)}
          value={selectedModel}
          onChange={handleSelectedModelChange}
          isRequired={false}
          helperText=""
        />
      </Grid>
      )
    }
  }

  return (
    <Container 
      maxWidth="md" 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'flex-start', 
        margin: 0, 
        padding: 0, 
        height: '100vh',
        justifyContent: 'flex-start',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography variant="h3" sx={{ marginBottom: '1rem' }}>Inference Dashboard</Typography>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%'}}>
            <Grid item>
              <Button variant="outlined" onClick={handleAddInferenceButton}>
                Add new inference
              </Button>
            </Grid>
            <Grid item>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant={activeButton === 'In Progress' ? 'contained' : 'outlined'}
                  color="primary"
                  sx={{ borderRadius: '0px' }}
                  onClick={() => handleFilterButtons('In Progress')}
                >
                  In Progress
                </Button>
                <Button
                  variant={activeButton === 'Done' ? 'contained' : 'outlined'}
                  sx={{ 
                    borderRadius: '0px',
                    backgroundColor: activeButton === 'Done' ? '#43a047' : 'transparent',
                    borderColor: '#43a047',
                    color: activeButton === 'Done' ? 'white' : '#43a047',
                    '&:hover': {
                      backgroundColor: activeButton === 'Done' ? '#43a047' : '#66bb6a',
                      borderColor: '#43a047',
                    },
                  }}
                  onClick={() => handleFilterButtons('Done')}
                >
                  Done
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {filteredInstances.map((data, index) => (
          <InferenceCard key={index} data={data} />))}
      </Grid>
      
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.3)', 
            opacity: '0.1 !important'
          },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: 550, bgcolor: 'background.paper', p: 2, borderRadius: 2 }}>
          <h2 id="child-modal-title">Choose a trained model</h2>
          <Grid container>
            <Grid item xs={5.5}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DropdownParameter
                    hyperparam_name='Dataset Name'
                    selectItems={trainedModels.map((dataset: any) => dataset.datasetName)}
                    value={selectedDatasetName}
                    onChange={handleSelectedDatasetNameChange}
                    isRequired={false}
                    helperText=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownParameter
                    hyperparam_name='Version'
                    selectItems={datasetVersions.map((version) => version.versionName)}
                    value={selectedDatasetVersion}
                    onChange={handleSelectedDatasetVersionChange}
                    isRequired={false}
                    helperText=""
                  />
                </Grid>
                {renderModelSelect()}
                
              </Grid>
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ height: '100%' }}/>
            </Grid>
            <Grid item xs={5.5} mt={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Grid container spacing={3} >
                <Grid item xs={12}>
                  <TextField
                    label="Instance Job Name"
                    value={InferenceJobName}
                    onChange={(e) => setInferenceJobName(e.target.value)}
                    sx = {{ width: '100%'}}>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <DropdownParameter
                    hyperparam_name='Deployed Instance'
                    selectItems={deployedInstances.map((instance) => instance.instance_id.toString())}
                    value={selectedDeployedInstance !== null ? selectedDeployedInstance.toString() : ''}
                    onChange={handleChooseDeployedInstance}
                    isRequired={true}
                    helperText=""
                  />
                </Grid>

                <Grid item xs={12}>
                  <Tooltip title={"Select your image zip file"}>
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      sx = {{ width: '100%'}}
                    >
                      Upload file
                      <VisuallyHiddenInput type="file" onChange={handleFileUpload} accept=".zip"/>
                    </Button>
                  </Tooltip>
                      {uploadedFileName && (
                        <Typography variant="body1" sx = {{ display: 'flex', width: '100%', justifyContent: 'center', color: '#bdbdbd'}}>
                          {uploadedFileName}
                        </Typography>
                      )}
                </Grid>
              </Grid>

            </Grid>

            <Grid item xs={12} mt={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button onClick={handleCloseModal}>Close</Button>
              <Button 
                variant="text" 
                endIcon={<CheckIcon />} 
                disabled={(!selectedModel || InferenceJobName === '')}
                onClick={handleSubmitTrainingModel}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}

export default InferenceDashboard;
