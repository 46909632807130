import { useGenerateModelFilesMutation, useTransferModelFilesMutation } from '../slices/modelsApiSlice';
import { ModelFilesParameters } from '../types/model';

export const useGenerateModelFilesAndTransfer = () => {
  const [generateModelFiles] = useGenerateModelFilesMutation();
  const [transferModelFiles] = useTransferModelFilesMutation();

  const generateModelFilesAndTransfer = async (modelFilesParameters: ModelFilesParameters, instance_id: string) => {
    try {
      // Generate model files
      let response = await generateModelFiles({modelFilesParameters, instance_id}).unwrap();

      let modelId = response.data.modelId;
      // After generating model files, transfer them to the instance
      await transferModelFiles({ instance_id, modelId }).unwrap();

      // Return true if successfully generated and transferred model files
      return true;

    } catch (error: any) {
      if (error.status === 409 || error.status === 500) {
        alert("Model is not ready for training, please wait for a moment.");
      } else {
        console.error("An error occurred:", error);
      }

      // Return false if there was an error
      return false;
    }
  };

  return { generateModelFilesAndTransfer };
};

export function getCollectionName(instance_id: string, modelName: string, datasetName: string, datasetVersion: string): string {
  // Remove spaces from modelName and datasetName
  const modelNameNoSpaces = removeSpaces(modelName);
  const datasetNameNoSpaces = removeSpaces(datasetName);
  const datasetVersionNoSpaces = removeSpaces(datasetVersion);

  // TODO: Hash collection name
  // const collection_name = "1" + "_" + instanceData.instance_id + "_" + modelNameNoSpaces + "_" +datasetNameNoSpaces + "_" + datasetVersionNoSpaces;
  const collectionName = instance_id + "_" + modelNameNoSpaces + "_" +datasetNameNoSpaces + "_" + datasetVersionNoSpaces;

  return collectionName;
}

function removeSpaces(str: string): string {
  return str.replace(/\s+/g, '');
}
