import React from "react";
const backendUrl = process.env.REACT_APP_WEBSOCKET_URL;

export type TrainingData = {
  epochs: number[];
  loss: number[];
  valLoss: number[];
  diceCoefficient: number[];
  valDiceCoefficient: number[];
  iou: number[];
  valIou: number[];
  lr: number[];
};

export default function startWebsocket( modelId: string, setTrainingData: React.Dispatch<React.SetStateAction<TrainingData>>) {
  const user_id = 1;
  const ws = new WebSocket(`${backendUrl}/data/ws/${user_id}/${modelId}`); 
  
  ws.onopen = () => {
  };

  ws.onmessage = (event: MessageEvent) => {
    const data = JSON.parse(event.data);

    // Check if the incoming message is an array (initial data) or an object (updates)
    if (Array.isArray(data)) {
      // Handle bulk data, assuming it's an array of epoch data
      const transformedData = data.reduce<TrainingData>((acc, item) => {
        acc.epochs.push(item.epoch);
        acc.loss.push(item.logs.loss);
        acc.valLoss.push(item.logs.val_loss);
        acc.diceCoefficient.push(item.logs.dice_coefficient);
        acc.valDiceCoefficient.push(item.logs.val_dice_coefficient);
        acc.iou.push(item.logs.iou);
        acc.valIou.push(item.logs.val_iou);
        acc.lr.push(item.logs.lr);
        return acc;
      }, {
        epochs: [],
        loss: [],
        valLoss: [],
        diceCoefficient: [],
        valDiceCoefficient: [],
        iou: [],
        valIou: [],
        lr: []
      });
      setTrainingData(transformedData);
    } else {
      // Handle single update data, assuming it's an object
      setTrainingData((prevData: TrainingData) => ({
        epochs: [...prevData.epochs, data.epoch],
        loss: [...prevData.loss, data.logs.loss],
        valLoss: [...prevData.valLoss, data.logs.val_loss],
        diceCoefficient: [...prevData.diceCoefficient, data.logs.dice_coefficient],
        valDiceCoefficient: [...prevData.valDiceCoefficient, data.logs.val_dice_coefficient],
        iou: [...prevData.iou, data.logs.iou],
        valIou: [...prevData.valIou, data.logs.val_iou],
        lr: [...prevData.lr, data.logs.lr]
      }));
    }
  
  };

  ws.onclose = () => {
    console.log("WebSocket connection closed");
  };

  return () => {
    ws.close(); // Close WebSocket connection when component unmounts
  };
}
