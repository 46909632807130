import { Dispatch, SetStateAction } from 'react';

export function processImage(imageFile: File, setUniqueClasses: Dispatch<SetStateAction<number[]>>, callback: (classes: number[]) => void) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');  

  if (!ctx) {
    return; 
  }

  const img = new Image();

  img.onload = () => {
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);
    const imageData = ctx.getImageData(0, 0, img.width, img.height);
    const data = imageData.data;

    const uniqueClasses = new Set<number>();
    for (let i = 0; i < data.length; i += 4) {
      if (data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255) {
        data[i] = data[i + 1] = data[i + 2] = 1;
      }
      uniqueClasses.add(data[i]); // Pixel are in (R, G, B, A) format
    }
    const uniqueClassesArray = Array.from(uniqueClasses);
    setUniqueClasses([...uniqueClassesArray]);
    callback(uniqueClassesArray);
  };
  
    img.src = URL.createObjectURL(imageFile);

}
  