import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useGetDeployedInstancesQuery } from '../../slices/vastApiSlice';
import TrainingMetricsCard from '../../components/training/TrainingMetricsCard';
import Loader from '../../components/Loader';

export default function LiveTrainingDashboard() {
  const { data: deployedInstances = [], error: isLoadInstancesError, isLoading: isLoadingInstances } = useGetDeployedInstancesQuery(1);

  if (isLoadingInstances){
    return(
      <Loader circularProgressSize={50} message="Loading data..." />
    )
  }


  return (
    <Container maxWidth="md">
      
      <Typography variant="h3">Training Jobs</Typography>
      { deployedInstances.length === 0 ? 
        (
          <Grid item xs={12}>
            <Alert severity='info'>There are no training jobs!</Alert>
          </Grid>
        ) : 
        (
          <>
            {deployedInstances.map((instance, index) => (
              <Accordion key={index} sx={{ mb: 4, maxWidth:"800px" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />} 
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    borderBottom: '1px solid #ccc',
                    bgcolor: 'background.paper'
                  }}
                >
                  <Grid container spacing={1} sx={{ minHeight: "120px" }}>
                    <Grid item xs={12}>
                      <Typography variant="h5">{instance.modelName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2"><strong>Dataset Name:</strong> {instance.datasetName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2"><strong>Dataset Version:</strong> {instance.datasetVersion}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2"><strong>Deployed date:</strong> {instance.created}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2"><strong>Instance ID:</strong> {instance.instance_id}</Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{bgcolor: 'rgba(0, 0, 0, 0.1)'}}>
                  { instance.modelId ? (
                    <TrainingMetricsCard modelId={instance.modelId} />
                  ) : (
                    <Alert severity="warning">There is no model training in instance</Alert>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
    </Container>
  );
}
