import { useUploadImageFileMutation } from '../slices/uploadImageApiSlice';
import { useCallback } from 'react';

export interface UploadURLs {
  [fileName: string]: string;
}

const messages = [
  "Uploading data information...",
  "Uploading images now...",
  "Almost there, hang tight...",
  "Finalizing upload..."
];

const useUploadFiles = () => {
  const [uploadImageFile] = useUploadImageFileMutation();

  const uploadFiles = useCallback(async (files: File[], uploadUrls: UploadURLs, setProgress: (progress: number) => void, setMessage: (message: string) => void) => {
    let uploadedFiles = 0;
    const totalFiles = files.length;

    const updateProgress = () => {
      uploadedFiles++;
      const progress = (uploadedFiles / totalFiles) * 100;
      setProgress(progress);

      if (progress > 75) {
        setMessage(messages[3]);
      } else if (progress > 50) {
        setMessage(messages[2]);
      } else if (progress > 25) {
        setMessage(messages[1]);
      } else if (progress > 0) {
        setMessage(messages[0]);
      }
    };

    const promises = files.map((file) => {
      const url = uploadUrls[file.name];
      return uploadImageFile({ url, file, updateProgress }).unwrap();
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error('Error uploading files: ', error);
    }
  }, [uploadImageFile]);

  return { uploadFiles };
};

export default useUploadFiles;
