import { FC } from 'react'
import { Select, MenuItem, FormGroup, Typography, FormHelperText } from '@mui/material';

interface BoolParameterProps {
    hyperparam_name: string;
		value: boolean;
    helperText: string;
		onChange: (value: boolean) => void;
}

const BoolParameter: FC<BoolParameterProps> = ( { hyperparam_name, value, helperText, onChange } ) => {

  return (
    <FormGroup>
      <Typography variant="subtitle1" gutterBottom>
        { hyperparam_name }
      </Typography>

      <Select
        size="small"  
        value={value ? 'true' : 'false'} 
        onChange={(event) => onChange(event.target.value === 'true')} 
      >
        <MenuItem value={'true'}>Yes</MenuItem>
        <MenuItem value={'false'}>No</MenuItem>
      </Select>
      <FormHelperText>{ helperText }</FormHelperText>
    </FormGroup>
  )
}

export default BoolParameter;