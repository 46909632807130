import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { deepPurple } from '@mui/material/colors';
import { useMemo } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ColorModeContext, useColorMode } from './components/ColorMode';
import Onboarding from './components/Onboarding';
import SideNavbar from './components/SideNavbar';
import DatasetDashboard from './pages/dataset/DatasetDashboard';
import DatasetUpload from './pages/dataset/DatasetUpload';
import HomePage from './pages/home/HomePage';
import InferenceDashboard from './pages/inference/InferenceDashboard';
import InstanceDashboard from './pages/instances/InstanceDashboard';
import UserInstanceDashboard from './pages/instances/UserInstanceDashboard';
import LiveTrainingDashboard from './pages/liveTraining/LiveTrainingDashboard';
import ModelDashboard from './pages/model/ModelDashboard';
import ModelParameters from './pages/ModelParameters';
import Report from './pages/Report';

export default function App() {
  const { mode, toggleMode } = useColorMode();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: deepPurple,
          secondary: deepPurple,
        },
      }),
    [mode]
  );

  function StyledRoutes() {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          marginLeft: {
            md: '250px', // margin left on medium devices and up ( sideNavbar is 250px wide)
          },
          padding: {
            xs: '10px', // smaller padding on extra small devices
            sm: '20px', // larger padding on small devices and up
          },
        }}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/dataset" element={<DatasetDashboard />} />
          <Route path="/dataset_upload" element={<DatasetUpload />} />
          <Route path="/hyperparameters" element={<ModelParameters />} />
          <Route path="/instancedashboard" element={<InstanceDashboard />} />
          <Route path="/myinstances" element={<UserInstanceDashboard />} />
          {/* <Route path="/dataset/model_dashboard" element={<ModelDashboard />} /> */}
          <Route path="/live_training" element={<LiveTrainingDashboard />} />
          <Route path="/model_dashboard" element={<ModelDashboard />} />
          <Route path="/inference" element={<InferenceDashboard />} />
          <Route path="/report" element={<Report />} />
        </Routes>
      </Box>
    );
  }

  return (
    <ColorModeContext.Provider value={{ mode, toggleMode }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <SideNavbar />
          <StyledRoutes />
          <Onboarding />
        </Router>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
